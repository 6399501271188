<template>
<div>
	<v-card>
		<v-card-title>
			创建团队信息
		</v-card-title>
		<v-divider></v-divider>
		<div class="pa-3">
			<v-form
				ref="form"
				class="pa-3"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
				<v-text-field
					v-model="name"
					:counter="2"
					outlined
					:rules="nameRules"
					label="团队名称"
					required
				></v-text-field>
				<!-- <v-select
					outlined
					v-model="teamType"
					:items="teamTypes"
					item-text="text"
          			item-value="value"
					label="团队类型"
				></v-select> -->

				<div class="d-flex justify-space-between mt-4">					
					<v-btn
						color="error"
						text
						@click="reset"
						>
						重新填写
					</v-btn>
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						@click="validate"
						type="submit"
					>
						添加
					</v-btn>
				</div>
			</v-form>
		</div>
	</v-card>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		</div>
</template>

<script>

import WorkTeamService from '../../services/WorkTeam';

export default {
	name: 'teamForm',
	data: () => ({
		valid: true,
		name: '',
		nameRules: [
			v => !!v || '请输入团队名称',
			v => (v && v.length >= 2) || '项目名称至少2个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		teamType: null,
		teamTypes: [
			{ value: 'team', text: '团队' },
			{ value: 'individual', text: '个人' }],
		team_id: null,
		workers: [],
		availableWorkers: [],
		assignWorker: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
	}),
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.name,
				"type": 'team'
			}

			this.createWorkTeam(body);
		},
		async createWorkTeam(body){
			try {
				let response = await WorkTeamService.createWorkTeam(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.reset();
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		}
	}
}
</script>
